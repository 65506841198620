import { ELayers, IAIData } from "@types"
import { FilterController } from "../fragments"

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function getAreasOverlay() {
  class AreasOverlay extends google.maps.OverlayView {
    private predictionArea: google.maps.Rectangle | null = null

    constructor(
      map: google.maps.Map,
      private probability: number,
      private bounds: IAIData["bounds"],
      private filterController: FilterController
    ) {
      super()
      this.setMap(map)
    }

    onAdd(): void {
      this.predictionArea = new google.maps.Rectangle({
        strokeColor: this.probabilityToColor(),
        strokeOpacity: 0.35,
        strokeWeight: 2,
        fillColor: this.probabilityToColor(),
        fillOpacity: 0.1,
        map: this.getMap() as google.maps.Map,
        bounds: this.bounds,
        visible: this.filterController.hasPassedFilters(ELayers.PRED_AREA),
      })
    }

    setProbability = (probability: number): void => {
      this.probability = probability
      this.update()
    }

    setBounds = (bounds: IAIData["bounds"]): void => {
      this.bounds = bounds
      this.predictionArea?.setBounds(this.bounds)
    }

    filter(): void {
      const isVisible = this.filterController.hasPassedFilters(ELayers.PRED_AREA)
      this.predictionArea?.setVisible(isVisible)
    }

    private update(): void {
      const color = this.probabilityToColor()

      this.predictionArea?.setValues({
        strokeColor: color,
        fillColor: color,
      })
    }

    private probabilityToColor(): string {
      if (this.probability < 50) return "transparent"

      const baseIntensity = 255
      const fraction = 255 / 50

      //   const baseIntensity = this.probability <= 60 ? 255 : 100
      //   const fraction = this.probability <= 60 ? 155 / 10 : 100 / 40
      const intensity = Math.round(baseIntensity - fraction * (this.probability - 50))

      return `rgb(255, ${intensity}, 0)`
    }

    onRemove(): void {
      this.predictionArea?.setMap(null)
    }
  }

  return AreasOverlay
}
