import { ILocale, IQuake } from "@types"
import { QuakeMarkerFactory } from "../map_elements"
import { FilterController } from "../fragments"

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function getQuakesOverlay() {
  class QuakesOverlay extends google.maps.OverlayView {
    private quakeMarkerFactory: QuakeMarkerFactory

    constructor(private quakeList: IQuake[], filterController: FilterController, l: ILocale) {
      super()

      this.quakeMarkerFactory = new QuakeMarkerFactory(quakeList, () => this.getProjection(), filterController, l)
    }

    onAdd(): void {
      const panes = this.getPanes()!

      this.quakeMarkerFactory.createElements(panes.overlayLayer, panes.overlayMouseTarget, (element: Element): void =>
        QuakesOverlay.preventMapHitsAndGesturesFrom(element)
      )
    }

    draw(): void {
      const overlayProjection = this.getProjection()
      this.quakeMarkerFactory.calcPositions(overlayProjection)
    }

    onRemove(): void {
      this.quakeMarkerFactory.dispose()
    }

    filter(): void {
      this.quakeMarkerFactory.filter()
    }

    closeTooltip = (): void => {
      this.quakeMarkerFactory.closeTooltip()
    }

    onMessage = (data: Partial<IQuake>): void => {
      this.quakeMarkerFactory.onMessage(data)

      const overlayProjection = this.getProjection()

      this.quakeMarkerFactory.calcPositions(overlayProjection)
    }

    reset = (quakes: IQuake[]): void => {
      this.quakeMarkerFactory.reset(quakes)
      this.onAdd()
      this.draw()
    }
  }

  return QuakesOverlay
}
