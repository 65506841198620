import { IStation } from "@types"
import { StationLabelFactory } from "../map_elements"
import { FilterController } from "../fragments"

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function getStationsOverlay() {
  class StationsOverlay extends google.maps.OverlayView {
    private stationLabelFactory: StationLabelFactory

    constructor(private stations: IStation[], filterController: FilterController) {
      super()

      this.stationLabelFactory = new StationLabelFactory(stations, filterController)
    }

    onAdd(): void {
      const panes = this.getPanes()!

      this.stationLabelFactory.createElements(panes.overlayMouseTarget, (element: Element): void =>
        StationsOverlay.preventMapHitsAndGesturesFrom(element)
      )
    }

    draw(): void {
      const overlayProjection = this.getProjection()
      this.stationLabelFactory.calcPositions(overlayProjection)
    }

    setVisible = (isVisible: boolean): void => {
      this.stationLabelFactory.setVisible(isVisible)
    }

    setActive(id: number, isActive: boolean): void {
      this.stationLabelFactory.setActive(id, isActive)
    }

    onRemove(): void {
      this.stationLabelFactory.dispose()
    }

    filter(): void {
      this.stationLabelFactory.filter()
    }

    reset(stations: IStation[]): void {
      this.stationLabelFactory.reset(stations)
      this.onAdd()
      this.draw()
    }
  }

  return StationsOverlay
}
