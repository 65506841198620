import { IBuildingsMarker, ILayerMarker, IPopulationMarker } from "@types"
import { FilterController } from "../fragments"
import { PopulationMarkerFactory, TailingsMarkerFactory } from "../map_elements/layer_markers"
import { BuildingsMarkerFactory } from "../map_elements/layer_markers"

interface ILayerData {
  tailings: ILayerMarker[]
  buildings: IBuildingsMarker[]
  population: IPopulationMarker[]
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function getLayersOverlay() {
  class LayersOverlay extends google.maps.OverlayView {
    private tailingsFactory: TailingsMarkerFactory
    private buildingsFactory: BuildingsMarkerFactory
    private populationFactory: PopulationMarkerFactory

    constructor(layerData: ILayerData, filterController: FilterController) {
      super()

      this.tailingsFactory = new TailingsMarkerFactory(layerData.tailings, filterController)
      this.buildingsFactory = new BuildingsMarkerFactory(layerData.buildings, filterController)
      this.populationFactory = new PopulationMarkerFactory(layerData.population, filterController)
    }

    onAdd(): void {
      const panes = this.getPanes()!

      this.tailingsFactory.createElements(panes.overlayMouseTarget, (element: Element): void =>
        LayersOverlay.preventMapHitsAndGesturesFrom(element)
      )
      this.buildingsFactory.createElements(panes.overlayMouseTarget, (element: Element): void =>
        LayersOverlay.preventMapHitsAndGesturesFrom(element)
      )
      this.populationFactory.createElements(panes.overlayMouseTarget, (element: Element): void =>
        LayersOverlay.preventMapHitsAndGesturesFrom(element)
      )
    }

    draw(): void {
      const overlayProjection = this.getProjection()
      this.tailingsFactory.calcPositions(overlayProjection)
      this.buildingsFactory.calcPositions(overlayProjection)
      this.populationFactory.calcPositions(overlayProjection)
    }

    setVisible = (isVisible: boolean): void => {
      this.tailingsFactory.setVisible(isVisible)
      this.buildingsFactory.setVisible(isVisible)
      this.populationFactory.setVisible(isVisible)
    }

    onRemove(): void {
      this.tailingsFactory.dispose()
      this.buildingsFactory.dispose()
      this.populationFactory.dispose()
    }

    filter(): void {
      this.tailingsFactory.filter()
      this.buildingsFactory.filter()
      this.populationFactory.filter()
    }

    reset(layerData: ILayerData): void {
      this.resetElements(layerData)
      this.onAdd()
      this.draw()
    }

    private resetElements(layerData: ILayerData): void {
      this.tailingsFactory.reset(layerData.tailings)
      this.buildingsFactory.reset(layerData.buildings)
      this.populationFactory.reset(layerData.population)
    }
  }

  return LayersOverlay
}
